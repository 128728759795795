@media only screen and (min-width:1580px){
    .propertyBottomSvg{
        display: none !important;
     }
    .header a:hover{
        color:#CD0001!important;
    }
    .header-background-MD{
        display: none !important;
    }
    .header-background-LG{
        display: none !important;
    }
    .header-background-XL{
        width: 100% !important;
    }
    .top-wave-svg-sm{
        display: none !important;
    }
  .copule-svg{
    position: absolute;
    top: 14%;
    z-index: 2000;
    right: 26%;
    width: 24%;
    height: 80vh;
    background-image: url('../img/copule.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;;
  }
    .wave-bottom{
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 45vh;
    }
 
    
    .main-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-x: hidden !important;
    }
    .container{
        font-family: 'roboto';
        position: relative;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        
    }
    .top-wave{
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: stretch;

    }
    .top-wave-svg{
        width: 100% !important;
    }
    .new2{
        width: 100% !important;
    }
    .header{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 90%;
        margin: 10px auto;

    }
    .header a{
        font-family: "roboto-medium";
        text-decoration: none !important;
        color: #222048;
        margin-right: 2%;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .activeTag{
        font-family: 'roboto-bold';
        color: #EA5522 !important;
        border-bottom: 1px solid #EA5522;
    }
    .main{
        width: 100%;
        overflow: hidden;
    }

    .propertyBottom{
        width: 100%;
        height: 50% !important;
    }

    .logo{
        width: 8%;
        margin-right: 2%;
        position: absolute;
        top: 22%;
        right: 29%;
        z-index: 4000;
    }
    .copule-img {
        position: absolute;
        top: 20%;
        right: 21%;
        z-index: 2000;
        width: 21% !important;
    }
    .Wave-bottom-img {
        width: 100% !important;
        height: 27%;
        align-self: flex-end;
        position: absolute;
        bottom: 0;
        /* height: 35vh; */
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 30%;
        left: 21%;
        text-align: center;
    }
    .find-txt h2{
        font-size: 45px;
    }
    .find-txt p{
        font-size: 20px;
    }
    .second-part{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .second-part-container{
        width: 85%;
        margin: 5% auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
    .girl-img{
        width: 39%;
    }
    .property-txt{
        width: 44%;
        font-family: 'roboto-regular';
        line-height: 30px;
        color: #222048;
    }
    .third-part{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        width: 85%;
        margin: 5% auto;
    }
    .why-haloo-txt{
        width: 40%;
        font-family: 'roboto-regular';
        line-height: 30px;
        color: #222048;
    }
    .why-haloo-pic{
        width: 35%;
    }
    footer{
        width: 100%;
        background: #222048;
    }
    .footer-container{
        width:85%;
        margin: 0 auto;
        height: 100px !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .logo-footer{
        width: 14%;
    }
    .logo-footer img{
        width:85% !important;
    }
    .footer-link{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        font-family: 'roboto-regular';
    }
    .footer-link a{
        text-decoration: none !important;
        color: white;
        font-size: 14px;
        margin-right: 5%;
    }
    aside{
        background-color: #FFA84F !important;
        fill: white !important;
        border-radius: 50% !important;
        padding: 11px !important;
        height: 31px !important;
        bottom: 36px !important;
        width: 30px !important;
        right: 26px !important;
    }
    aside:focus{
        outline: none !important;
    }
    /* -------contact-us------------------ */
    .header-component{
        width: 100% !important;
        position: relative;
    }
    .activeTagHeader{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 139px;
        height: 42px;
        background: #FFA84F;
        border-radius: 12px;
        color: white;
        font-family: 'roboto-bold';
    }
    .contact-us-container{
        width: 100%;
        min-height: 100vh;
    }
    .logo-head{
        width: 100% !important;
    }
    .logo-head img{
        width: 100% !important;
    }
    .link-head {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        position: absolute;
        top: 15%;
        left: 4%;
    }
    .link-head a{
        text-decoration: none !important;
        color: white;
        font-family: 'roboto-medium';
        text-align: center;
        margin-right: 5%;
    }
    .haloo-logo-head{
        display: flex;
        flex-direction: row;
        width: 11% !important;
        position: absolute;
        top:15%;
        right: 4%;
    }
    .haloo-logo-head img{
        width: 100% !important;
    }
    .content-contact-us{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 2%;
    }
    .content-contact-us h2{
        color: #CD0001;
        font-family: 'roboto-medium';
        font-size:38px;
        margin-bottom: 10px !important;
    }
    .pic-girl {
        order: 2;
        width:30% !important;
        margin-left: 2%;
        height: fit-content;
        align-self: center;
    }
    .pic-girl img{
        width: 100%;
    }
    .txt-and-form{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50% !important;
    }
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
    }
    .nameInput , .emailInput,textarea{
        width: 70%;
        border: 2px solid #FFA84F;
        border: 3px solid #FFA84F;
       box-sizing: border-box;
       border-radius: 17px;
       height: 40px;
       margin-bottom: 2%;
       padding: 2%;
       margin-top: 2%;
    }
    textarea{
        height: 150px !important;
        margin-top: 2%;
        margin-bottom: 2%;
    }
    .submitBtn{
        margin-top: 2%;
    width: 20%;
    height:40px;
    background: #FFA84F;
    border-radius: 17px;
    border: 0 !important;
    font-family: 'roboto-regular';
    font-size: 17px;
    color: white;
    }
    .txt-and-form p{
        font-family: 'roboto-regular';
        font-size: 17px;
        color: #222048;
        width: 50%;
        text-align: center;
    }
    ::-webkit-input-placeholder{
        font-family: 'roboto-regular';
        color: #222048;
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-family: 'roboto-regular';
        color: #222048;
      }
      ::placeholder {
        font-family: 'roboto-regular';
        color: #222048;
      }
      .footer-contact-us{
          width: 100% !important;
          position: absolute;
          bottom: 0;
      }
      .errorMsg{
          font-family: 'roboto-regular';
          font-size: 15px;
          color: #222048 ;
      }
      .successMsg{
          margin-top:1%;
        font-family: 'roboto-regular';
        font-size: 15px;
        color: #222048 ;
      }
      /* ---------about-us------------ */
      .about-us-container{
        width: 100%;
        min-height: 100vh;
    }
    .footer-about-us{
        width: 100% !important;
        position: absolute;
        bottom: 0;
    }
    .content-about-us{
        width: 100% !important;
        display: flex;
        flex-direction: row;
        justify-content:space-around;
        margin-top: 2%;
    }
    .about-txt{
        width: 47%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .about-pic{
        width: 33%;
        height: fit-content;
        align-self: center;
    }
    .about-pic img{
        width: 100%;
    }
    .content-about-us h2{
        color: #CD0001;
        font-family: 'roboto-medium';
        font-size:38px;
        margin-bottom: 10px !important;
    }
    .content-about-us p{
        font-family: 'roboto-regular';
        font-size: 16px;
        line-height: 30px;
        color: #222048 ;
    }
    /* ------terms----------- */
    .terms-container{
        width: 100%;
        height: 100vh !important;
        position: relative;
    }
    .footer-terms{
        width: 100% !important;
        position: absolute !important;
        bottom: 0;
    }
    .content-terms{
        width: 45% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin:2% auto;
    }
    .content-terms h2{
        color: #CD0001;
        font-family: 'roboto-medium';
        font-size:35px;
        margin-bottom: 10px !important;
    }
    
    .content-terms p{
        font-family: 'roboto-regular';
        font-size: 16px;
        line-height: 30px;
        color: #222048 ;
    }
}
@media only screen and (min-width:1300px) and (max-width:1579px){
    .propertyBottomSvg{
        display: none !important;
     }
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .header-background-MD{
        display: none !important;
    }
    .header-background-LG{
        display: none !important;
    }
    .header-background-XL{
        width: 100% !important;
    }
    .top-wave-svg-sm{
        display: none !important;
    }
    .copule-svg{
        position: absolute;
        top: 14%;
        z-index: 2000;
        right: 24%;
        width: 26%;
        height: 80vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;;
      }
        .wave-bottom{
            background-image: url('../img/wave2.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: bottom;
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 45vh;
        }
    
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 90%;
            margin: 10px auto;
    
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 33%;
            left: 17%;
            text-align: center;
        }
        .find-txt h2{
            font-size: 45px;
        }
        .find-txt p{
            font-size: 20px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 39%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 35%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 31px !important;
            bottom: 36px !important;
            width: 30px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 11% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:30% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 20%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: absolute;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: absolute !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (min-width:1200px) and (max-width:1299px){
    .propertyBottomSvg{
        display: none !important;
     }
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .header-background-MD{
        display: none !important;
    }
    .header-background-LG{
        display: none !important;
    }
    .header-background-XL{
        width: 100% !important;
    }
    .top-wave-svg-sm{
        display: none !important;
    }
    .copule-svg{
        position: absolute;
        top: 14%;
        z-index: 2000;
        right: 19%;
        width: 29%;
        height: 80vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;;
      }
        .wave-bottom{
            background-image: url('../img/wave2.svg');
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: bottom;
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 45vh;
        }
     
   
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 90%;
            margin: 10px auto;
    
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 32%;
            left: 15%;
            text-align: center;
        }
        .find-txt h2{
            font-size: 45px;
        }
        .find-txt p{
            font-size: 20px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 39%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 35%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 31px !important;
            bottom: 36px !important;
            width: 30px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 11% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:30% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 20%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: absolute;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: absolute !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (min-width:927px) and (max-width:991px){
    .propertyBottomSvg{
        display: none !important;
     }
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .header-background-MD{
        display: 100%;
    }
    .header-background-LG{
        display: none;
    }
    .header-background-XL{
        display: none;
    }
    .copule-svg {
        position: absolute;
        top: 11%;
        z-index: 2000;
        right: 2%;
        width: 45%;
        height: 80vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 90%;
            margin: 10px auto;
    
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 34%;
            left: 10%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 34px;
        }
        .find-txt p {
            font-size: 15px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 41%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 41%;
        }
        footer{
            width: 100%;
            background: #222048;
            width: 100%;
            background: #222048;
          
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 25px !important;
            bottom: 36px !important;
            width: 24px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 20% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:41% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 30%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 14px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 14px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: fixed;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
         
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:32px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 15px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
            margin-bottom: 100px !important;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (min-width:992px) and (max-width:1199px){
    .propertyBottomSvg{
        display: none !important;
     }
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .header-background-MD{
        display: none !important;
    }
    .copule-svg {
        position: absolute;
        top: 14%;
        z-index: 2000;
        right: 11%;
        width: 35%;
        height: 80vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 152%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 90%;
            margin: 10px auto;
    
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 35%;
            left: 15%;
            text-align: center;
        }
        .find-txt h2{
            font-size: 45px;
        }
        .find-txt p{
            font-size: 15px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 39%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 35%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 28px !important;
            bottom: 36px !important;
            width: 27px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 11% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:30% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 20%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: absolute;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: absolute !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (min-width:768px) and (max-width:926px){
    .propertyBottomSvg{
       display: none !important;
    }
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .header-background-MD{
        display: 100%;
    }
    .header-background-LG{
        display: none;
    }
    .header-background-XL{
        display: none;
    }
    .copule-svg {
        position: absolute;
        top: 11%;
        z-index: 2000;
        right: 2%;
        width: 45%;
        height: 80vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 90%;
            margin: 10px auto;
            font-size: 15px;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 34%;
            left: 10%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 34px;
        }
        .find-txt p {
            font-size: 15px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 41%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
            text-align: center;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
            text-align: center;
        }
        .why-haloo-pic{
            width: 41%;
        }
        footer{
            width: 100%;
            background: #222048;
            width: 100%;
            background: #222048;
          
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 25px !important;
            bottom: 36px !important;
            width: 24px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 13% !important;
            position: absolute;
            top:3%;
            right: 2%;
        }
        .haloo-logo-head img{
            width: 75% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:41% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 100px !important;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 30%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .submitBtn:focus{
            outline: none !important;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: fixed;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-medium';
              font-size: 14px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 14px;
            color: #222048 ;
          }
          input:focus, textarea:focus{
              outline: none !important;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: fixed;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
         
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 100px !important;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:32px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 15px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:0 auto;
            margin-bottom: 100px !important;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        
}
@media only screen and (min-width:576px) and (max-width:595px){
    .top-wave-svg{
        display: none;
    }
    .top-wave-svg-sm{
        width: 100% !important;
    }
    .header-background-XL{
        display: none !important;
    }
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .copule-svg {
        position: absolute;
        top: 20%;
        z-index: 2000;
        right: 12%;
        width: 59%;
        height: 80vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
  
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 14px auto;
            justify-content: center;
            font-size: 15px;
            position: absolute;
            top: 2%;
            margin: 0 auto;
        }
        .header a {
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: white;
            margin-right: 4%;
        }
        .activeTag {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #FFDF71 !important;
            border-bottom: 1px solid #FFDF71;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottomSvg{
            width: 100%;
        }
        .propertyBottom{
            display: none;
        }
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 12%;
            left: 28%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 27px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 55%;
        }
        .property-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
            font-size: 15px;
            text-align: center;
        }
        .third-part{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
            font-size: 15px;
            text-align: center;
        }
        .why-haloo-pic{
            width: 65%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 22%;
        }
        .logo-footer img{
            width:95% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 80%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 18px !important;
            bottom: 78px !important;
            width: 16px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 31px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head {
            width: 117% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 18%;
            margin: 0 auto;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
            font-size: 15px;
        }
        .haloo-logo-head {
            display: flex;
            height: fit-content;
            flex-direction: row;
            width: 13% !important;
            position: absolute;
            top: 39%;
            right: 3%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            top: 15%;
            width: 100% !important;
            height: fit-content;
            align-self: center;
            position: fixed;
            z-index: 500;
        }
        .pic-girl img {
            width: 100%;
            opacity: 0.5;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90% !important;
            z-index: 3000;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput, .emailInput, textarea {
            background: rgba(255,168,79,0.2);
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
            box-sizing: border-box;
            border-radius: 17px;
            height: 40px;
            margin-bottom: 2%;
            padding: 2%;
            margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width:35%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p {
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 90%;
            text-align: center;
            background-color: rgba(255,168,79,0.2);
            border-radius: 28px;
            padding: 10px;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          input:focus,textarea:focus{
              outline: none !important;
          }
          /* ---------about-us------------ */
          .about-us-container {
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden;
        }
        .footer-about-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 4000;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic {
            width: 82%;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 24%;
            z-index: 500;
        }
        .about-pic img {
            width: 100%;
            opacity: 0.5;
        }
        .content-about-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 3px !important;
            margin-top: 5px !important;
        }
        .content-about-us p {
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048;
            z-index: 3000;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
            overflow-x: hidden !important;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 90% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:30px;
            margin-bottom: 10px !important;
            margin-top: 5px;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (min-width:596px) and (max-width:767px){
    .top-wave-svg{
        display: none;
    }
    .top-wave-svg-sm{
        width: 100% !important;
    }
    .header-background-XL{
        display: none !important;
    }
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .copule-svg {
        position: absolute;
        top: 21%;
        z-index: 2000;
        right: 21%;
        width: 49%;
        height: 80vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
  
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 14px auto;
            justify-content: center;
            font-size: 15px;
            position: absolute;
            top: 2%;
            margin: 0 auto;
        }
        .header a {
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: white;
            margin-right: 4%;
        }
        .activeTag {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #FFDF71 !important;
            border-bottom: 1px solid #FFDF71;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottomSvg{
            width: 100%;
        }
        .propertyBottom{
            display: none;
        }
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 12%;
            left: 29%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 27px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 55%;
        }
        .property-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
            font-size: 15px;
            text-align: center;
        }
        .third-part{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
            font-size: 15px;
            text-align: center;
        }
        .why-haloo-pic{
            width: 65%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 22%;
        }
        .logo-footer img{
            width:95% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 80%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 18px !important;
            bottom: 78px !important;
            width: 16px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 31px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
            overflow: hidden;
        }
        .logo-head {
            width: 117% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 18%;
            margin: 0 auto;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
            font-size: 15px;
        }
        .haloo-logo-head {
            display: flex;
            height: fit-content;
            flex-direction: row;
            width: 13% !important;
            position: absolute;
            top: 39%;
            right: 3%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            top: 15%;
            width: 100% !important;
            height: fit-content;
            align-self: center;
            position: fixed;
            z-index: 500;
        }
        .pic-girl img {
            width: 100%;
            opacity: 0.5;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90% !important;
            z-index: 3000;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput, .emailInput, textarea {
            background: rgba(255,168,79,0.2);
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
            box-sizing: border-box;
            border-radius: 17px;
            height: 40px;
            margin-bottom: 2%;
            padding: 2%;
            margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width:35%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p {
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 90%;
            text-align: center;
            background-color: rgba(255,168,79,0.2);
            border-radius: 28px;
            padding: 10px;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: fixed;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          input:focus,textarea:focus{
              outline: none !important;
          }
          /* ---------about-us------------ */
          .about-us-container {
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden;
        }
        .footer-about-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 4000;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic {
            width: 82%;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 24%;
            z-index: 500;
        }
        .about-pic img {
            width: 100%;
            opacity: 0.5;
        }
        .content-about-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 3px !important;
            margin-top: 5px !important;
        }
        .content-about-us p {
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048;
            z-index: 3000;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
            overflow-x: hidden !important;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 90% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:30px;
            margin-bottom: 10px !important;
            margin-top: 5px;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (min-width:320px) and (max-width:365px){
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .copule-svg {
        position: absolute;
        top: 19%;
        z-index: 2000;
        right: -1%;
        width: 87%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin: 10px auto;
            position: absolute;
            z-index: 3000;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 17%;
            left: 10%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 27px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 39%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 35%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 23px !important;
            bottom: 36px !important;
            width: 23px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 11% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:30% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 20%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: absolute;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: absolute !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (min-width:366px) and (max-width:415px){
    .header a:hover{
        color:#CD0001!important;
    }
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .copule-svg {
        position: absolute;
        top: 23%;
        z-index: 2000;
        right: 0%;
        width: 84%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin: 10px auto;
            position: absolute;
            z-index: 3000;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 17%;
            left: 15%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 27px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 39%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 35%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 23px !important;
            bottom: 36px !important;
            width: 23px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 11% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:30% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 20%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: absolute;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: absolute !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }

}
@media only screen and (min-width:416px) and (max-width:510px){
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .copule-svg {
        position: absolute;
        top: 23%;
        z-index: 2000;
        right: 6%;
        width: 72%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin: 10px auto;
            position: absolute;
            z-index: 3000;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 15%;
            left: 23%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 27px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 39%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 35%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 23px !important;
            bottom: 36px !important;
            width: 23px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 11% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:30% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 20%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: absolute;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: absolute !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }

}
@media only screen and (min-width:511px) and (max-width:575px){
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
    .copule-svg {
        position: absolute;
        top: 23%;
        z-index: 2000;
        right: 16%;
        width: 56%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin: 10px auto;
            position: absolute;
            z-index: 3000;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: #222048;
            margin-right: 2%;
        
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #EA5522 !important;
            border-bottom: 1px solid #EA5522;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottom{
            width: 100%;
            height: 50% !important;
        }
    
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 17%;
            left: 26%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 27px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img{
            width: 39%;
        }
        .property-txt{
            width: 44%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
        }
        .why-haloo-txt{
            width: 40%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #222048;
        }
        .why-haloo-pic{
            width: 35%;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 100px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 14%;
        }
        .logo-footer img{
            width:85% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 14px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 23px !important;
            bottom: 36px !important;
            width: 23px !important;
            right: 26px !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 139px;
            height: 42px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img{
            width: 100% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 50%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a{
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 5%;
        }
        .haloo-logo-head{
            display: flex;
            flex-direction: row;
            width: 11% !important;
            position: absolute;
            top:15%;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .pic-girl {
            order: 2;
            width:30% !important;
            margin-left: 2%;
            height: fit-content;
            align-self: center;
        }
        .pic-girl img{
            width: 100%;
        }
        .txt-and-form{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 50% !important;
        }
        form{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 80%;
            margin: 0 auto;
        }
        .nameInput , .emailInput,textarea{
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
           box-sizing: border-box;
           border-radius: 17px;
           height: 40px;
           margin-bottom: 2%;
           padding: 2%;
           margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 20%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p{
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 50%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us{
              width: 100% !important;
              position: absolute;
              bottom: 0;
          }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #222048 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container{
            width: 100%;
            min-height: 100vh;
        }
        .footer-about-us{
            width: 100% !important;
            position: absolute;
            bottom: 0;
        }
        .content-about-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content:space-around;
            margin-top: 2%;
        }
        .about-txt{
            width: 47%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .about-pic{
            width: 33%;
            height: fit-content;
            align-self: center;
        }
        .about-pic img{
            width: 100%;
        }
        .content-about-us h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:38px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: absolute !important;
            bottom: 0;
        }
        .content-terms{
            width: 45% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:35px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (width:360px){
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
   .top-wave-svg{
       display: none;
   }
   .top-wave-svg-sm{
       width: 100% !important;
   }
    .copule-svg {
        position: absolute;
        top: 25%;
        z-index: 2000;
        right: 6%;
        width: 70%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 16px auto;
            position: absolute;
            z-index: 3000;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: white;
            margin-right: 4%;
            font-size: 13px;
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #FFDF71 !important;
            border-bottom: 1px solid #FFDF71;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottomSvg{
            width: 174%;
        }
        .propertyBottom{
            display: none !important;
        }
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 15%;
            left: 14%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 25px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            position: relative;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img {
            width: 95%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        .property-txt {
            width: 96%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            z-index: 2000;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
            position: relative;
        }
        .why-haloo-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
            z-index: 2000;
        }
        .why-haloo-pic{
            width: 105%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 50px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 25%;
        }
        .logo-footer img{
            width:100% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 70%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 11px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 15px !important;
            bottom: 78px !important;
            width: 14px !important;
            right: 26px !important;
            z-index: 5000 !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-background-XL{
            display: none !important;
        }
        .header-background-LG{
            display: none !important;
        }
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 94px;
            height: 26px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container {
            overflow-x: hidden;
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img {
            width: 150% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a {
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 4%;
            font-size: 13px;
        }
        .haloo-logo-head {
            display: flex;
            flex-direction: row;
            width: 17% !important;
            position: absolute;
            top: 15;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
            margin-top: 3px;
        }
        .pic-girl {
            width: 110% !important;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 32%;
        }
        .pic-girl img{
            width: 100%;
            opacity: 0.4;
        }
        .txt-and-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 95% !important;
            z-index: 2000;
        }
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 60px !important;
        }
        .nameInput, .emailInput, textarea {
            background: rgba(255,168,79,0.3);
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
            box-sizing: border-box;
            border-radius: 17px;
            height: 40px;
            margin-bottom: 2%;
            padding: 2%;
            margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 35%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p {
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 100%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container {
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden !important;
        }
        .footer-about-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
        .content-about-us {
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 2%;
        }
        .about-txt {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2000;
            text-align: center;
            margin-bottom: 60px !important;
        }
        .about-pic {
            width: 92%;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 27%;
        }
        .about-pic img {
            width: 100%;
            opacity: 0.5;
            z-index: 500;
        }
        .content-about-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            overflow-x: hidden !important;
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 95% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
            text-align: center;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:30px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (width:411px){
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
   .top-wave-svg{
       display: none;
   }
   .top-wave-svg-sm{
       width: 100% !important;
   }
    .copule-svg {
        position: absolute;
        top: 25%;
        z-index: 2000;
        right: 6%;
        width: 70%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 16px auto;
            position: absolute;
            z-index: 3000;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: white;
            margin-right: 4%;
            font-size: 13px;
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #FFDF71 !important;
            border-bottom: 1px solid #FFDF71;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottomSvg{
            width: 174%;
        }
        .propertyBottom{
            display: none !important;
        }
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 15%;
            left: 14%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 25px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            position: relative;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img {
            width: 95%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        .property-txt {
            width: 96%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            z-index: 2000;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
            position: relative;
        }
        .why-haloo-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
            z-index: 2000;
        }
        .why-haloo-pic{
            width: 105%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 50px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 25%;
        }
        .logo-footer img{
            width:100% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 70%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 11px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 15px !important;
            bottom: 78px !important;
            width: 14px !important;
            right: 26px !important;
            z-index: 5000 !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-background-XL{
            display: none !important;
        }
        .header-background-LG{
            display: none !important;
        }
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 94px;
            height: 26px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container {
            overflow-x: hidden;
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img {
            width: 150% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a {
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 4%;
            font-size: 13px;
        }
        .haloo-logo-head {
            display: flex;
            flex-direction: row;
            width: 17% !important;
            position: absolute;
            top: 15;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
            margin-top: 3px;
        }
        .pic-girl {
            width: 110% !important;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 32%;
        }
        .pic-girl img{
            width: 100%;
            opacity: 0.4;
        }
        .txt-and-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 95% !important;
            z-index: 2000;
        }
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 60px !important;
        }
        .nameInput, .emailInput, textarea {
            background: rgba(255,168,79,0.3);
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
            box-sizing: border-box;
            border-radius: 17px;
            height: 40px;
            margin-bottom: 2%;
            padding: 2%;
            margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 35%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p {
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 100%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container {
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden !important;
        }
        .footer-about-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
        .content-about-us {
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 2%;
        }
        .about-txt {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2000;
            text-align: center;
            margin-bottom: 60px !important;
        }
        .about-pic {
            width: 92%;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 27%;
        }
        .about-pic img {
            width: 100%;
            opacity: 0.5;
            z-index: 500;
        }
        .content-about-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            overflow-x: hidden !important;
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 85% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
            text-align: center;
            margin-bottom: 50px !important;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:30px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
}
@media only screen and (width:320px){
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
   .top-wave-svg{
       display: none;
   }
   .top-wave-svg-sm{
       width: 100% !important;
   }
    .copule-svg {
        position: absolute;
        top: 25%;
        z-index: 2000;
        right: 6%;
        width: 70%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 16px auto;
            position: absolute;
            z-index: 3000;
            padding: 5px;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: white;
            margin-right: 4%;
            font-size: 13px;
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #FFDF71 !important;
            border-bottom: 1px solid #FFDF71;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottomSvg{
            width: 174%;
        }
        .propertyBottom{
            display: none !important;
        }
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 13%;
            left: 11%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 22px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            position: relative;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img {
            width: 95%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        .property-txt {
            width: 96%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            z-index: 2000;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
            position: relative;
        }
        .why-haloo-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
            z-index: 2000;
        }
        .why-haloo-pic{
            width: 105%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 50px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 25%;
        }
        .logo-footer img{
            width:100% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 77%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 11px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 15px !important;
            bottom: 78px !important;
            width: 14px !important;
            right: 26px !important;
            z-index: 5000 !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-background-XL{
            display: none !important;
        }
        .header-background-LG{
            display: none !important;
        }
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 94px;
            height: 26px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container {
            overflow-x: hidden;
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img {
            width: 150% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a {
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 4%;
            font-size: 13px;
        }
        .haloo-logo-head {
            display: flex;
            flex-direction: row;
            width: 17% !important;
            position: absolute;
            top: 15;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
            margin-top: 3px;
        }
        .pic-girl {
            width: 110% !important;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 32%;
        }
        .pic-girl img{
            width: 100%;
            opacity: 0.4;
        }
        .txt-and-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 95% !important;
            z-index: 2000;
        }
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 60px !important;
        }
        .nameInput, .emailInput, textarea {
            background: rgba(255,168,79,0.3);
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
            box-sizing: border-box;
            border-radius: 17px;
            height: 40px;
            margin-bottom: 2%;
            padding: 2%;
            margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 35%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p {
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 100%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container {
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden !important;
        }
        .footer-about-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
        .content-about-us {
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 2%;
        }
        .about-txt {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2000;
            text-align: center;
            margin-bottom: 60px !important;
        }
        .about-pic {
            width: 92%;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 27%;
        }
        .about-pic img {
            width: 100%;
            opacity: 0.5;
            z-index: 500;
        }
        .content-about-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            overflow-x: hidden !important;
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 85% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
            text-align: center;
            margin-bottom: 50px !important;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:30px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
       
}
@media only screen and (width:375px){
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
   .top-wave-svg{
       display: none;
   }
   .top-wave-svg-sm{
       width: 100% !important;
   }
    .copule-svg {
        position: absolute;
        top: 25%;
        z-index: 2000;
        right: 6%;
        width: 70%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 16px auto;
            position: absolute;
            z-index: 3000;
            padding: 5px;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: white;
            margin-right: 4%;
            font-size: 13px;
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #FFDF71 !important;
            border-bottom: 1px solid #FFDF71;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottomSvg{
            width: 174%;
        }
        .propertyBottom{
            display: none !important;
        }
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 13%;
            left: 11%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 22px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            position: relative;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img {
            width: 95%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        .property-txt {
            width: 96%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            z-index: 2000;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
            position: relative;
        }
        .why-haloo-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
            z-index: 2000;
        }
        .why-haloo-pic{
            width: 105%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 50px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 25%;
        }
        .logo-footer img{
            width:100% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 77%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 11px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 15px !important;
            bottom: 78px !important;
            width: 14px !important;
            right: 26px !important;
            z-index: 5000 !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-background-XL{
            display: none !important;
        }
        .header-background-LG{
            display: none !important;
        }
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 94px;
            height: 26px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container {
            overflow-x: hidden;
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img {
            width: 150% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a {
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 4%;
            font-size: 13px;
        }
        .haloo-logo-head {
            display: flex;
            flex-direction: row;
            width: 17% !important;
            position: absolute;
            top: 15;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
            margin-top: 3px;
        }
        .pic-girl {
            width: 110% !important;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 32%;
        }
        .pic-girl img{
            width: 100%;
            opacity: 0.4;
        }
        .txt-and-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 95% !important;
            z-index: 2000;
        }
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 60px !important;
        }
        .nameInput, .emailInput, textarea {
            background: rgba(255,168,79,0.3);
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
            box-sizing: border-box;
            border-radius: 17px;
            height: 40px;
            margin-bottom: 2%;
            padding: 2%;
            margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 35%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p {
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 100%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container {
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden !important;
        }
        .footer-about-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
        .content-about-us {
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 2%;
        }
        .about-txt {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2000;
            text-align: center;
            margin-bottom: 60px !important;
        }
        .about-pic {
            width: 92%;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 27%;
        }
        .about-pic img {
            width: 100%;
            opacity: 0.5;
            z-index: 500;
        }
        .content-about-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            overflow-x: hidden !important;
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 85% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
            text-align: center;
            margin-bottom: 50px !important;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:30px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
       
}
@media only screen and (width:414px){
    .link-head a:hover{
        color: #FFCBB3 !important;
    }
   .top-wave-svg{
       display: none;
   }
   .top-wave-svg-sm{
       width: 100% !important;
   }
    .copule-svg {
        position: absolute;
        top: 25%;
        z-index: 2000;
        right: 6%;
        width: 70%;
        height: 79vh;
        background-image: url('../img/copule.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 251%;
        position: absolute;
        bottom: 0;
        height: 63vh;
    }
    .wave-bottom {
        background-image: url('../img/wave2.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: bottom;
        width: 222%;
        position: absolute;
        bottom: 0;
        height: 43vh;
    }
        .header-background-LG{
            display: none !important;
        }
        .main-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow-x: hidden !important;
        }
        .container{
            font-family: 'roboto';
            position: relative;
            width: 100vw;
            height: 100vh;
            display: flex;
            flex-direction: column;
            
        }
        .top-wave{
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: stretch;
    
        }
        .top-wave-svg{
            width: 100% !important;
        }
        .new2{
            width: 100% !important;
        }
        .header {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 16px auto;
            position: absolute;
            z-index: 3000;
        }
        .header a{
            font-family: "roboto-medium";
            text-decoration: none !important;
            color: white;
            margin-right: 4%;
            font-size: 13px;
        }
        .activeTag{
            font-family: 'roboto-bold';
            color: #FFDF71 !important;
            border-bottom: 1px solid #FFDF71;
        }
        .main{
            width: 100%;
            overflow: hidden;
        }
    
        .propertyBottomSvg{
            width: 174%;
        }
        .propertyBottom{
            display: none !important;
        }
        .logo{
            width: 8%;
            margin-right: 2%;
            position: absolute;
            top: 22%;
            right: 29%;
            z-index: 4000;
        }
        .copule-img {
            position: absolute;
            top: 20%;
            right: 21%;
            z-index: 2000;
            width: 21% !important;
        }
        .Wave-bottom-img {
            width: 100% !important;
            height: 27%;
            align-self: flex-end;
            position: absolute;
            bottom: 0;
            /* height: 35vh; */
        }
        .find-txt {
            font-family: 'roboto-medium';
            color: #222048;
            position: absolute;
            top: 15%;
            left: 19%;
            text-align: center;
        }
        .find-txt h2 {
            font-size: 25px;
        }
        .find-txt p {
            font-size: 13px;
        }
        .second-part {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            position: relative;
        }
        .second-part-container{
            width: 85%;
            margin: 5% auto;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }
        .girl-img {
            width: 95%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        .property-txt {
            width: 96%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            z-index: 2000;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
        }
        .third-part{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            width: 85%;
            margin: 5% auto;
            position: relative;
        }
        .why-haloo-txt{
            width: 95%;
            font-family: 'roboto-regular';
            line-height: 30px;
            color: #070C23;
            text-align: center;
            background-color: rgba(17,21,71,0.04);
            padding: 10px;
            border-radius: 12px;
            z-index: 2000;
        }
        .why-haloo-pic{
            width: 105%;
            position: absolute;
            opacity: 0.2;
            z-index: 500;
        }
        footer{
            width: 100%;
            background: #222048;
        }
        .footer-container{
            width:85%;
            margin: 0 auto;
            height: 50px !important;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .logo-footer{
            width: 25%;
        }
        .logo-footer img{
            width:100% !important;
        }
        .footer-link{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 70%;
            font-family: 'roboto-regular';
        }
        .footer-link a{
            text-decoration: none !important;
            color: white;
            font-size: 11px;
            margin-right: 5%;
        }
        aside{
            background-color: #FFA84F !important;
            fill: white !important;
            border-radius: 50% !important;
            padding: 11px !important;
            height: 15px !important;
            bottom: 78px !important;
            width: 14px !important;
            right: 26px !important;
            z-index: 5000 !important;
        }
        aside:focus{
            outline: none !important;
        }
        /* -------contact-us------------------ */
        .header-background-XL{
            display: none !important;
        }
        .header-background-LG{
            display: none !important;
        }
        .header-component{
            width: 100% !important;
            position: relative;
        }
        .activeTagHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 94px;
            height: 26px;
            background: #FFA84F;
            border-radius: 12px;
            color: white;
            font-family: 'roboto-bold';
        }
        .contact-us-container {
            overflow-x: hidden;
            width: 100%;
            min-height: 100vh;
        }
        .logo-head{
            width: 100% !important;
        }
        .logo-head img {
            width: 150% !important;
        }
        .link-head {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: absolute;
            top: 15%;
            left: 4%;
        }
        .link-head a {
            text-decoration: none !important;
            color: white;
            font-family: 'roboto-medium';
            text-align: center;
            margin-right: 4%;
            font-size: 13px;
        }
        .haloo-logo-head {
            display: flex;
            flex-direction: row;
            width: 17% !important;
            position: absolute;
            top: 15;
            right: 4%;
        }
        .haloo-logo-head img{
            width: 100% !important;
        }
        .content-contact-us{
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 2%;
        }
        .content-contact-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
            margin-top: 3px;
        }
        .pic-girl {
            width: 110% !important;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 32%;
        }
        .pic-girl img{
            width: 100%;
            opacity: 0.4;
        }
        .txt-and-form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 95% !important;
            z-index: 2000;
        }
        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 60px !important;
        }
        .nameInput, .emailInput, textarea {
            background: rgba(255,168,79,0.3);
            width: 70%;
            border: 2px solid #FFA84F;
            border: 3px solid #FFA84F;
            box-sizing: border-box;
            border-radius: 17px;
            height: 40px;
            margin-bottom: 2%;
            padding: 2%;
            margin-top: 2%;
        }
        textarea{
            height: 150px !important;
            margin-top: 2%;
            margin-bottom: 2%;
        }
        .submitBtn{
            margin-top: 2%;
        width: 35%;
        height:40px;
        background: #FFA84F;
        border-radius: 17px;
        border: 0 !important;
        font-family: 'roboto-regular';
        font-size: 17px;
        color: white;
        }
        .txt-and-form p {
            font-family: 'roboto-regular';
            font-size: 17px;
            color: #222048;
            width: 100%;
            text-align: center;
        }
        ::-webkit-input-placeholder{
            font-family: 'roboto-regular';
            color: #222048;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            font-family: 'roboto-regular';
            color: #222048;
          }
          ::placeholder {
            font-family: 'roboto-regular';
            color: #222048;
          }
          .footer-contact-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
          .errorMsg{
              font-family: 'roboto-regular';
              font-size: 15px;
              color: #CD0001 ;
          }
          .successMsg{
              margin-top:1%;
            font-family: 'roboto-regular';
            font-size: 15px;
            color: #222048 ;
          }
          /* ---------about-us------------ */
          .about-us-container {
            width: 100%;
            min-height: 100vh;
            overflow-x: hidden !important;
        }
        .footer-about-us {
            width: 100% !important;
            position: fixed;
            bottom: 0;
            z-index: 3000;
        }
        .content-about-us {
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-top: 2%;
        }
        .about-txt {
            width: 95%;
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 2000;
            text-align: center;
            margin-bottom: 60px !important;
        }
        .about-pic {
            width: 92%;
            height: fit-content;
            align-self: center;
            position: fixed;
            top: 27%;
        }
        .about-pic img {
            width: 100%;
            opacity: 0.5;
            z-index: 500;
        }
        .content-about-us h2 {
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size: 30px;
            margin-bottom: 10px !important;
        }
        .content-about-us p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
        /* ------terms----------- */
        .terms-container{
            overflow-x: hidden !important;
            width: 100%;
            height: 100vh !important;
            position: relative;
        }
        .footer-terms{
            width: 100% !important;
            position: fixed !important;
            bottom: 0;
        }
        .content-terms{
            width: 85% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin:2% auto;
            text-align: center;
            margin-bottom: 50px !important;
        }
        .content-terms h2{
            color: #CD0001;
            font-family: 'roboto-medium';
            font-size:30px;
            margin-bottom: 10px !important;
        }
        
        .content-terms p{
            font-family: 'roboto-regular';
            font-size: 16px;
            line-height: 30px;
            color: #222048 ;
        }
       
}
@media only screen and (width:640px) and (height:360px){
    .wave-bottom {
        background-image: url(/static/media/wave2.887d7081.svg);
        /* background-size: 100%; */
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 43vh;
        background-size: contain;
    }
    .copule-svg {
        position: absolute;
        top: 18%;
        z-index: 2000;
        right: 8%;
        width: 45%;
        height: 80vh;
        background-image: url(/static/media/copule.a4b5d0ba.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 24%;
        left: 13%;
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 14px auto;
        justify-content: center;
        font-size: 15px;
        position: absolute;
        top: 5%;
        margin: 0 auto;
    }
    .about-txt {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px !important;
    }
    .footer-about-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px;
    }
    .footer-about-us .footer-container{
        height: 50px !important;
    }

    .footer-contact-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px !important;
    }
    .footer-contact-us .footer-container{
        height:50px !important;
    }
    .header-component {
        width: 100% !important;
        position: relative;
        z-index: 1000;
    }
    .pic-girl {
        top: 15%;
        width: 74% !important;
        height: fit-content;
        align-self: center;
        position: fixed;
        z-index: 500;
    }
    .txt-and-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90% !important;
        z-index: 3000;
        margin-bottom: 60px !important;
    }
    .footer-terms {
        width: 100% !important;
        position: fixed !important;
        bottom: 0;
        height:50px !important;
    }
    .footer-terms .footer-container{
        height:50px !important;
    }
    .content-terms {
        width: 90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2% auto;
        margin-bottom: 70px !important;
    }
}
@media only screen and (width:731px) and (height:411px){
    .wave-bottom {
        background-image: url(/static/media/wave2.887d7081.svg);
        /* background-size: 100%; */
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 43vh;
        background-size: contain;
    }
    .copule-svg {
        position: absolute;
        top: 18%;
        z-index: 2000;
        right: 8%;
        width: 45%;
        height: 80vh;
        background-image: url(/static/media/copule.a4b5d0ba.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 24%;
        left: 13%;
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 14px auto;
        justify-content: center;
        font-size: 15px;
        position: absolute;
        top: 5%;
        margin: 0 auto;
    }
    .about-txt {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px !important;
    }
    .footer-about-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px;
    }
    .footer-about-us .footer-container{
        height: 50px !important;
    }

    .footer-contact-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px !important;
    }
    .footer-contact-us .footer-container{
        height:50px !important;
    }
    .header-component {
        width: 100% !important;
        position: relative;
        z-index: 1000;
    }
    .pic-girl {
        top: 15%;
        width: 74% !important;
        height: fit-content;
        align-self: center;
        position: fixed;
        z-index: 500;
    }
    .txt-and-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90% !important;
        z-index: 3000;
        margin-bottom: 60px !important;
    }
    .footer-terms {
        width: 100% !important;
        position: fixed !important;
        bottom: 0;
        height:50px !important;
    }
    .footer-terms .footer-container{
        height:50px !important;
    }
    .content-terms {
        width: 90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2% auto;
        margin-bottom: 70px !important;
    }
}
@media only screen and (width:823px) and (height:411px){
    .wave-bottom {
        background-image: url(/static/media/wave2.887d7081.svg);
        /* background-size: 100%; */
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 43vh;
        background-size: contain;
    }
    .copule-svg {
        position: absolute;
        top: 18%;
        z-index: 2000;
        right: 8%;
        width: 45%;
        height: 80vh;
        background-image: url(/static/media/copule.a4b5d0ba.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 24%;
        left: 13%;
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 14px auto;
        justify-content: center;
        font-size: 15px;
        position: absolute;
        top: 5%;
        margin: 0 auto;
    }
    
}
@media only screen and (width:568px) and (height:320px){
    .wave-bottom {
        background-image: url(/static/media/wave2.887d7081.svg);
        /* background-size: 100%; */
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 43vh;
        background-size: contain;
    }
    .copule-svg {
        position: absolute;
        top: 18%;
        z-index: 2000;
        right: 8%;
        width: 45%;
        height: 80vh;
        background-image: url(/static/media/copule.a4b5d0ba.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 24%;
        left: 13%;
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 14px auto;
        justify-content: center;
        font-size: 15px;
        position: absolute;
        top: 5%;
        margin: 0 auto;
    }
    .about-txt {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px !important;
    }
    .footer-about-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px;
    }
    .footer-about-us .footer-container{
        height: 50px !important;
    }

    .footer-contact-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px !important;
    }
    .footer-contact-us .footer-container{
        height:50px !important;
    }
    .header-component {
        width: 100% !important;
        position: relative;
        z-index: 1000;
    }
    .pic-girl {
        top: 15%;
        width: 74% !important;
        height: fit-content;
        align-self: center;
        position: fixed;
        z-index: 500;
    }
    .txt-and-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90% !important;
        z-index: 3000;
        margin-bottom: 60px !important;
    }
    .footer-terms {
        width: 100% !important;
        position: fixed !important;
        bottom: 0;
        height:50px !important;
    }
    .footer-terms .footer-container{
        height:50px !important;
    }
    .content-terms {
        width: 90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2% auto;
        margin-bottom: 70px !important;
    }
}

@media only screen and (width:667px) and (height:375px){
    .wave-bottom {
        background-image: url(/static/media/wave2.887d7081.svg);
        /* background-size: 100%; */
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 43vh;
        background-size: contain;
    }
    .copule-svg {
        position: absolute;
        top: 18%;
        z-index: 2000;
        right: 8%;
        width: 45%;
        height: 80vh;
        background-image: url(/static/media/copule.a4b5d0ba.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 24%;
        left: 13%;
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 14px auto;
        justify-content: center;
        font-size: 15px;
        position: absolute;
        top: 5%;
        margin: 0 auto;
    }
    .about-txt {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px !important;
    }
    .footer-about-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px;
    }
    .footer-about-us .footer-container{
        height: 50px !important;
    }

    .footer-contact-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px !important;
    }
    .footer-contact-us .footer-container{
        height:50px !important;
    }
    .header-component {
        width: 100% !important;
        position: relative;
        z-index: 1000;
    }
    .pic-girl {
        top: 15%;
        width: 74% !important;
        height: fit-content;
        align-self: center;
        position: fixed;
        z-index: 500;
    }
    .txt-and-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90% !important;
        z-index: 3000;
        margin-bottom: 60px !important;
    }
    .footer-terms {
        width: 100% !important;
        position: fixed !important;
        bottom: 0;
        height:50px !important;
    }
    .footer-terms .footer-container{
        height:50px !important;
    }
    .content-terms {
        width: 90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2% auto;
        margin-bottom: 70px !important;
    }
}
@media only screen and (width:736px) and (height:414px){
    .wave-bottom {
        background-image: url(/static/media/wave2.887d7081.svg);
        /* background-size: 100%; */
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 43vh;
        background-size: contain;
    }
    .copule-svg {
        position: absolute;
        top: 18%;
        z-index: 2000;
        right: 8%;
        width: 45%;
        height: 80vh;
        background-image: url(/static/media/copule.a4b5d0ba.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 24%;
        left: 13%;
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 14px auto;
        justify-content: center;
        font-size: 15px;
        position: absolute;
        top: 5%;
        margin: 0 auto;
    }
    .about-txt {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px !important;
    }
    .footer-about-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px;
    }
    .footer-about-us .footer-container{
        height: 50px !important;
    }

    .footer-contact-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px !important;
    }
    .footer-contact-us .footer-container{
        height:50px !important;
    }
    .header-component {
        width: 100% !important;
        position: relative;
        z-index: 1000;
    }
    .pic-girl {
        top: 15%;
        width: 74% !important;
        height: fit-content;
        align-self: center;
        position: fixed;
        z-index: 500;
    }
    .txt-and-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90% !important;
        z-index: 3000;
        margin-bottom: 60px !important;
    }
    .footer-terms {
        width: 100% !important;
        position: fixed !important;
        bottom: 0;
        height:50px !important;
    }
    .footer-terms .footer-container{
        height:50px !important;
    }
    .content-terms {
        width: 90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2% auto;
        margin-bottom: 70px !important;
    }
}
@media only screen and (width:812px) and (height:375px){
    .wave-bottom {
        background-image: url(/static/media/wave2.887d7081.svg);
        /* background-size: 100%; */
        background-repeat: no-repeat;
        background-position: bottom;
        width: 100%;
        position: absolute;
        bottom: 0;
        height: 43vh;
        background-size: contain;
    }
    .copule-svg {
        position: absolute;
        top: 18%;
        z-index: 2000;
        right: 8%;
        width: 45%;
        height: 80vh;
        background-image: url(/static/media/copule.a4b5d0ba.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }
    .find-txt {
        font-family: 'roboto-medium';
        color: #222048;
        position: absolute;
        top: 24%;
        left: 13%;
        text-align: center;
    }
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 14px auto;
        justify-content: center;
        font-size: 15px;
        position: absolute;
        top: 5%;
        margin: 0 auto;
    }
    .about-txt {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px !important;
    }
    .footer-about-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px;
    }
    .footer-about-us .footer-container{
        height: 50px !important;
    }

    .footer-contact-us {
        width: 100% !important;
        position: fixed;
        bottom: 0;
        z-index: 4000;
        height: 50px !important;
    }
    .footer-contact-us .footer-container{
        height:50px !important;
    }
    .header-component {
        width: 100% !important;
        position: relative;
        z-index: 1000;
    }
    .pic-girl {
        top: 15%;
        width: 74% !important;
        height: fit-content;
        align-self: center;
        position: fixed;
        z-index: 500;
    }
    .txt-and-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 90% !important;
        z-index: 3000;
        margin-bottom: 60px !important;
    }
    .footer-terms {
        width: 100% !important;
        position: fixed !important;
        bottom: 0;
        height:50px !important;
    }
    .footer-terms .footer-container{
        height:50px !important;
    }
    .content-terms {
        width: 90% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 2% auto;
        margin-bottom: 70px !important;
    }
}